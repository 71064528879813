var myFullpage = new fullpage('#fullpage', {
    verticalCentered: false,
    css3: true,
    hybrid:true,
    fitToSection: false,
    scrollBar:true,
    responsiveWidth: 992
});

var myFullpage = new fullpage('#ServicePage', {
    anchors: ['firstPage', 'secondPage','threePage', 'fourPage','fivePage', 'sixPage','sevenPage','eightPage'],
    menu: '#myMenu',
    verticalCentered: false,
    css3: true,
    hybrid:true,
    fitToSection: false,
    responsiveWidth: 992
   /* onLeave : function(index, nextIndex, direction){
        var leavingSlide = $(this);

        console.log(index);

        if (index.index == 7) {
            console.log("sdfsdfsdf");
            $(".service__menu__block").addClass("is-abs");
        } else {
            $(".service__menu__block").removeClass("is-abs");
        }
    }*/
});

var heightWrap = $(".wrapper__content").height(),
    heightWindow = $(window).height(),
    heightFooter = $("footer").height();

console.log(heightWrap);

$(window).scroll(function(){
    console.log(heightWrap - heightWindow);
    console.log(heightWindow);
    console.log(heightFooter);
    console.log($(this).scrollTop());

    if ($(this).scrollTop()>=(heightWrap - heightWindow)) {
        $(".service__menu__block").addClass("is-abs");
    } else {
        $(".service__menu__block").removeClass("is-abs");
    }
});

if ($(window).width() < 993) {
    $(".service").addClass("fp-auto-height").addClass("fp-normal-scroll");

}

if ($(window).width() < 993) {
    $(".section").addClass("fp-auto-height").addClass("fp-normal-scroll");

}

if ($(window).width() < 993) {
    $(".js-team-slider").owlCarousel({
        loop:true,
        center: true,
        margin:30,
        nav:true,
        autoWidth:true,
    });
} else {
    $(".js-team-tab .tab__block").each(function(index , elem) {
        $(this).find(".team__item").length ;
        console.log($(this).find(".team__item").length);
    
        if ($(this).find(".team__item").length > 4) {
            $(this).find(".js-team-slider").removeClass("team__slider");
            
            
            $(this).find(".js-team-slider").owlCarousel({
                loop:true,
                center: true,
                margin:30,
                nav:true,
                autoWidth:true,
            });
        }
    
        });
}

checkClasses();
$(".js-team-slider").on('translated.owl.carousel', function(event) {
    checkClasses();
});

function checkClasses(){
    var total = $('.js-team-slider .owl-stage .owl-item.active').length;

    $('.js-team-slider .owl-stage .owl-item').removeClass('firstActiveItem lastActiveItem');

    $('.js-team-slider .owl-stage .owl-item.active').each(function(index){
        if (index === 0) {
            // this is the first one
            $(this).prev().addClass('firstActiveItem');
        }
        if (index === total - 1 && total>1) {
            // this is the last one
            $(this).addClass('lastActiveItem');
        }
    });
}

$(".js-tab-arrow").on( "click", function() {
    $(this).parents(".tab__control").toggleClass("is-active");
    $(this).toggleClass("is-active");
});

$(".js-modal").on( "click", function() {
    var modal = $(this).data("modal");

    $(".js-popup[data-modal="+modal+"]").addClass("is-open");
    return false;
});

$(".js-popup-close").on( "click", function() {
    $(".js-popup").removeClass("is-open");
});

$(".form label").on( "click", function() {
    $(this).addClass("is-hidden");
    $(this).parents(".form__field").find(".js-input").focus();
});

$(".js-input").on( "blur", function() {

    var val = $(this).val() ;
    console.log(val);

   if (val.length == 0) {
        $(this).parents(".form__field").find(".label-main").removeClass("is-hidden");
    }

});

if ($('#map').length) {
    var init = function init() {
        var myMap = new ymaps.Map("map", {
            center: [54.177225, 37.573224],
            zoom: 16,
            controls: []
        });

        var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {
            hintContent: 'Собственный значок метки',
            balloonContent: 'Это красивая метка'
        }, {
            // Опции.
            // Необходимо указать данный тип макета.
            iconLayout: 'default#image',
            // Своё изображение иконки метки.
            iconImageHref: 'images/map.png',
            // Размеры метки.
            iconImageSize: [88, 108],
            // Смещение левого верхнего угла иконки относительно
            // её "ножки" (точки привязки).
            iconImageOffset: [-30, -108]
        });

        myMap.geoObjects.add(myPlacemark);
    };

    ymaps.ready(init);
}


$(".js-slider-tv").owlCarousel({
    loop:true,
    margin:10,
    nav:true,
    responsive:{
        0:{
            items:1
        },
        480:{
            items:1
        },
        768:{
            items:2,
            margin:15
        },
        992:{
            items:3
        }
    }
});


$(".js-client").owlCarousel({
    loop:true,
    margin:10,
    nav:true,
    responsive:{
        0:{
            items:1
        },
        480:{
            items:2
        },
        768:{
            items:3
        },
        992:{
            items:4
        },
        1200:{
            items:5
        }
    }
});




/*
$(".js-team-slider").owlCarousel({
    center: true,
    items:4,
    loop:true,
	margin:30
});


$(".js-slider-tv").owlCarousel({
    loop:true,
    margin:10,
    nav:true,
    item: 3
});*/




/*
$(".js-team-slider").owlCarousel({
    center: true,
    autoWidth:true,
	margin:30
});*/
/*
$(".js-slider-tv").owlCarousel({
    loop:false,
    margin:10,
    nav:true,
    item: 3
});*/

$(".js-fancy-video").fancybox({
    openEffect  : 'none',
    closeEffect : 'none',
    helpers : {
        media : {}
    }
});


$(".js-tab-link").on( "click", function() {
    var tab = $(this).data("tab");

    $(".js-tab-link").removeClass("is-active");
    $(this).addClass("is-active");

    $(".tab__control").removeClass("is-active");
    $(".js-tab-arrow").removeClass("is-active");

    $(".js-tab").removeClass("is-active");
    $(".js-tab[data-tab="+tab+"]").addClass("is-active");
});

/*CHECKBOX SLIDE*/

$(".js-check-slide input[type='checkbox']").change(function() {

    if ($(this).is(":checked")) { 
        $(this).parents(".js-check-slide").find("label").removeClass("is-active");
        $(this).parents(".js-check-slide").find(".js-check-two").addClass("is-active");
    } else {
        $(this).parents(".js-check-slide").find("label").removeClass("is-active");
        $(this).parents(".js-check-slide").find(".js-check-one").addClass("is-active");
    }

});

$(".js-gamburger").on( "click", function() {
    $(".js-menu").addClass("is-open");
});

$(".js-menu-close").on( "click", function() {
    $(".js-menu").removeClass("is-open");
});

$(".js-check-label").on( "click", function() {
    var checkItem = $(this).parents(".js-check-slide").find("input[type='checkbox']");


    $(".js-check-label").removeClass("is-active");
    $(this).addClass("is-active");

    if ($(checkItem).is(":checked")) { 
        $(checkItem).attr( 'checked', false );
    } else {
        $(checkItem).attr( 'checked', true );
    }
});
    $(".map-region-main area").mouseenter(
        function () {
      
            var region = $(this).data("tab"),
                topItem = $(".map-region__marker[data-tab="+region+"]").offset().top,
                leftItem = $(".map-region__marker[data-tab="+region+"]").offset().left;

            $(".map-region__small[data-tab="+region+"]").addClass("is-active");
            $(".map-region__marker[data-tab="+region+"]").addClass("is-hidden");
            $(".map-region__pointer").offset({top: topItem - 22, left: leftItem - 6}).addClass("is-active");
            $(".map-popup").offset({top: topItem - 222, left: leftItem - 26}).addClass("is-active");
        }
    );

    $(".js-select-fliter").select2({
		minimumResultsForSearch: -1 /*,
        containerCssClass: "city" */
	});


    $(".js-map-link").on( "click", function() {
        var region = $(this).data("tab"),
        topItem = $(".map-region__marker[data-tab="+region+"]").offset().top,
        leftItem = $(".map-region__marker[data-tab="+region+"]").offset().left;


        $(".map-region__small").removeClass("is-active");
        $(".map-region__marker").removeClass("is-hidden");
        $(".map-region__small[data-tab="+region+"]").addClass("is-active");
        $(".map-region__marker[data-tab="+region+"]").addClass("is-hidden");
        $(".map-region__pointer").offset({top: topItem - 22, left: leftItem - 6}).addClass("is-active");
        $(".map-popup").offset({top: topItem - 222, left: leftItem - 26}).addClass("is-active");
    });

    $(".map-region-main area").mouseleave(
        function () {

            var region = $(this).data("tab");


            if  ($(".map-popup").is(':hover')) {
                console.log('hovered'); 
            } else {
                $(".map-region__small[data-tab="+region+"]").removeClass("is-active");
                $(".map-region__marker[data-tab="+region+"]").removeClass("is-hidden");
                $(".map-region__pointer").removeClass("is-active");
                $(".map-popup").removeClass("is-active");
            }
        }
    );

    $(".map-popup").mouseleave(function () {
        $(".map-region__small").removeClass("is-active");
        $(".map-region__marker").removeClass("is-hidden");
        $(".map-region__pointer").removeClass("is-active");
        $(".map-popup").removeClass("is-active");
    }
);


    /*$('.map-region-main area').each(function () {
        $(this).mouseover(function (e) {
            console.log("sdf");
        });
    });*/

	/*$(".map-region-main area").on( "hover", function() {
        console.log("sdf");
        var region = $(this).data("tab");
        console.log(region);
        $(".map-region__small").addClass("is-active");
    });*/




